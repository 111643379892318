import {
  URL_SHIPPING_BASE,
  URL_GET_API_RECEIVERS,
} from "@services/common/utils/settings";
import responseEvaluation from "@services/common/utils/serviceResponseEvaluation";

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.data;
};

const getReceivers = async (
  {
    cuballamaId = null,
    searchBy = { phone: "", first_name: "", last_name: "" },
  },
  axiosInstance
) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_GET_API_RECEIVERS}`;
  const bodyData = {
    //"cuballama_id":cuballamaId,
    searchBy: searchBy,
  };

  return axiosInstance
    .post(apiURL, bodyData)
    .then((res) => {
      const evaluation = responseEvaluation(res);
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(res);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
export default getReceivers;
