import { useState, useContext } from "react";
//Context
import GlobalContext from "@context/global/GlobalContext";
//Hooks
import useCancellablePromise from "@customHooks/common/useCancellablePromise";
import useModal from "@customHooks/common/modal/useModal";
//import useLocalizedRoutes from '@customHooks/common/useLocalizedRoutes'
//Getters
import getProvinces from "@src/services/envios/locations/getProvinces";

export function useProvinces() {
  const { axiosInstance, setProvinces } = useContext(GlobalContext);
  const { handleModal } = useModal();
  const { cancellablePromise } = useCancellablePromise();
  const [loadingProvinces, setLoadingProvinces] = useState(false);
  //const { navigate } = useLocalizedRoutes()

  const getAllProvinces = async () => {
    setLoadingProvinces(true);
    return cancellablePromise(getProvinces(axiosInstance))
      .then((response) => {
        if (response.canceled) return null;
        if (response.status === "ok") {
          setProvinces(response.formattedData);
        } else {
          //Mostrar alerta
          if (response.useAlert) handleModal(response.alertData);
          //Hacer redirect
          //if(response.useRedirect) navigate(response.route || "HOME")
        }
        return response.formattedData;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingProvinces(false);
      });
  };
  return { loadingProvinces, setLoadingProvinces, getAllProvinces };
}
