import React, { useState, useEffect } from "react";
import {
  addToStorage,
  removeFromStorage,
  getFromStorage,
} from "@utils/storage";
import { refreshToken } from "@utils/refreshToken";
import { axiosAuthNoUsar } from "@src/utils/axiosInstances";

const GlobalContext = React.createContext({});

const cartStructure = {
  shippingtype: 1,
  sender_id: 0,
  receiver_id: 0,
  products: [
    // { example
    //   cartItemId: "316158",
    //   product: {
    //     id: 2576,
    //     key: 316158,
    //     qty: 3,
    //     price: 289.99,
    //     home_delivery_price: 0,
    //     canHomeDelivery: true,
    //     home_delivery: 1,
    //     data: {
    //       limit: 5,
    //       name: [{ es: "Producto de prueba", en: "Test product" }],
    //     }
    //   },
    // }
  ],
  subtotal: 0,
  total: 0,
};

export function GlobalProvider({ children }) {
  const defaultProvince = process.env.GATSBY_DEFAULT_PROVINCE;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [authorized, setAuthorized] = useState(null);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [alertData, setAlertData] = useState(null);
  const [ipData, setIpData] = useState(null);
  const [redirectRoute, setRedirectRoute] = useState(null);
  const [tempRoute, setTempRoute] = useState(null);
  const [sendOptions, setSendOptions] = useState(null);
  const [waitTime, setWaitTime] = useState(0);

  const [cart, setCart] = useState(null);
  const [cartCount, setCartCount] = useState(null);

  const [offlineCart, setOfflineCart] = useState(cartStructure);
  const [offlineCartCount, setOfflineCartCount] = useState(0);

  const [credit, setCredit] = useState(null);
  const [product, setProduct] = useState(null);
  const [search, setSearch] = useState(null);
  const [tempValue, setTempValue] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [langSwitcher, setLangSwitcher] = useState(true);
  const [agent, setAgent] = useState(null);
  const [currency, setCurrency] = useState(process.env.GATSBY_DEFAULT_CURRENCY);
  const [availableServices, setAvailableServices] = useState(null);
  const [hasData, setHasData] = useState(false);
  const [loadedWPComponentData, setLoadedWPComponentData] = useState(null);
  const [snackbars, setSnackbars] = useState({ counter: 0, queue: [] });
  /*ENVIOS*/
  const [searchString, setSearchString] = useState(null);
  const [provinces, setProvinces] = useState(null);
  const [showProvinces, setShowProvinces] = useState(true);
  const [searchProvinceId, setSearchProvinceId] = useState(defaultProvince);
  const [searchBy, setSearchBy] = useState(null);
  const [searchByCategories, setSearchByCategories] = useState(null);
  const [searchByBrands, setSearchByBrands] = useState(null);
  const [categories, setCategories] = useState(null);
  const [categoriesSeo, setCategoriesSeo] = useState(null);
  const [categoriesHits, setCategoriesHits] = useState(null);
  const [categoriesHome, setCategoriesHome] = useState(null);
  const [searchCategory, setSearchCategory] = useState(null);
  const [hasSubcategories, setHasSubcategories] = useState(false);
  const [searchSubcategory, setSearchSubcategory] = useState(null);
  const [searchProduct, setSearchProduct] = useState(null);
  const [searchSource, setSearchSource] = useState(null);
  const [dataSelected, setDataSelected] = useState(null);
  const [searchPage, setSearchPage] = useState(1);
  const [receivers, setReceivers] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [receiverCheckout, setReceiverCheckout] = useState(null);
  const [sender, setSender] = useState({ status: "loading" }); //Asignamos un estado para mostrar un spinner
  const [amazonStyle, setAmazonStyle] = useState(0);
  const [orderIdDetails, setOrderIdDetails] = useState(null);
  const [permissionGranted, setPermissionGranted] = useState(null)
  /*Payment*/
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [availableMethods, setAvailableMethods] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [balanceData, setBalanceData] = useState(null);
  const [preCheckoutData, setPreCheckoutData] = useState(null);

  const axiosInstance = axiosAuthNoUsar;

  useEffect(() => {
    const timer =
      waitTime > 0 && setInterval(() => setWaitTime(waitTime - 1), 1000);
    return () => clearInterval(timer);
  }, [waitTime]);

  const onTokenError = () => {
    removeFromStorage("token");
    removeFromStorage("stamp");
    removeFromStorage("agent");
    setAuthorized(false);
    setUserInfo(null);
    setAccountInfo(null);

    const newItems = [
      ...snackbars.queue,
      { type: "info", message: "No está conectado" },
    ];
    setSnackbars({ counter: newItems.length, queue: newItems });
  };

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      const session = getFromStorage("token");
      if (session?.access_token) {
        config.headers.Authorization = `Bearer ${session.access_token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const session = getFromStorage("token");
        if (!session?.refresh_token) return Promise.reject(onTokenError());

        return refreshToken({ refresh_token: session.refresh_token })
          .then((refreshResponse) => {
            if (refreshResponse?.status === "ok" && "data" in refreshResponse) {
              addToStorage("token", refreshResponse.data);
              setAuthorized(true);

              // Retry the original request with the new token
              originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.access_token}`;
              return axiosInstance(originalRequest);
            }
            return Promise.reject(onTokenError());
          })
          .catch((error) => {
            console.error(error);
            return Promise.reject(onTokenError());
          });
      }

      return Promise.reject(error);
    }
  );

  const goLogout = () => {
    setUserInfo(null);
    setAccountInfo(null);
    setPaymentMethods(null);
    setAvailableMethods(null);
    setSelectedMethod(null);
    setBalanceData(null);
    setAgent(null);
    setTempValue(null);
    setTempRoute(null);
    setAuthorized(false);
    setHasData(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        dataLoaded,
        setDataLoaded,
        authorized,
        setAuthorized,
        logoutLoading,
        setLogoutLoading,
        loginStatus,
        setLoginStatus,
        userInfo,
        setUserInfo,
        accountInfo,
        setAccountInfo,
        alertData,
        setAlertData,
        ipData,
        setIpData,
        redirectRoute,
        setRedirectRoute,
        tempRoute,
        setTempRoute,
        waitTime,
        setWaitTime,
        sendOptions,
        setSendOptions,
        cart,
        setCart,
        cartCount,
        setCartCount,
        cartStructure,
        offlineCart,
        setOfflineCart,
        offlineCartCount,
        setOfflineCartCount,
        credit,
        setCredit,
        axiosInstance,
        product,
        setProduct,
        tempValue,
        setTempValue,
        firstLoad,
        setFirstLoad,
        langSwitcher,
        setLangSwitcher,
        agent,
        setAgent,
        search,
        setSearch,
        currency,
        setCurrency,
        paymentMethods,
        setPaymentMethods,
        availableMethods,
        setAvailableMethods,
        balanceData,
        setBalanceData,
        selectedMethod,
        setSelectedMethod,
        hasData,
        setHasData,
        amazonStyle,
        setAmazonStyle,
        orderIdDetails,
        setOrderIdDetails,
        goLogout,
        availableServices,
        setAvailableServices,
        searchString,
        setSearchString,
        searchProvinceId,
        setSearchProvinceId,
        receiver,
        setReceiver,
        receivers,
        setReceivers,
        receiverCheckout,
        setReceiverCheckout,
        sender,
        setSender,
        searchBy,
        setSearchBy,
        searchByCategories,
        setSearchByCategories,
        searchByBrands,
        setSearchByBrands,
        searchPage,
        setSearchPage,
        provinces,
        setProvinces,
        showProvinces,
        setShowProvinces,
        searchCategory,
        setSearchCategory,
        categories,
        setCategories,
        categoriesSeo,
        setCategoriesSeo,
        categoriesHits,
        setCategoriesHits,
        categoriesHome,
        setCategoriesHome,
        hasSubcategories,
        setHasSubcategories,
        searchSubcategory,
        setSearchSubcategory,
        searchProduct,
        setSearchProduct,
        searchSource,
        setSearchSource,
        dataSelected,
        setDataSelected,
        loadedWPComponentData,
        setLoadedWPComponentData,
        snackbars,
        setSnackbars,
        preCheckoutData,
        setPreCheckoutData,
        permissionGranted,
        setPermissionGranted,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
export default GlobalContext;
