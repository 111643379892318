import { useEffect, useContext } from "react";
import GlobalContext from "@context/global/GlobalContext";
import { getFromStorage, removeFromStorage } from "@src/utils/storage";
import useCancellablePromise from "@src/customHooks/common/useCancellablePromise";
import useModal from "@src/customHooks/common/modal/useModal";
import { useOnlineCart } from "@src/customHooks/envios/cart/useOnlineCart";
import getCartContentService from "@services/envios/cart/getCartContent";

const useGetInitialCart = () => {
    const { userInfo, setOfflineCart, setOfflineCartCount, setCart, setCartCount, axiosInstance } =
        useContext(GlobalContext);

    const { cancellablePromise } = useCancellablePromise();
    const { handleModal } = useModal()
    const { updateCartItem, addCartItem, resetCart } = useOnlineCart();

    const countCart = (cart) =>
        cart.products
            .map((item) => item.product.qty || 1)
            .reduce((acc, item) => acc + item, 0);


    //Servicios que requieren currency
    useEffect(() => {
        if (!userInfo) return;

        cancellablePromise(getCartContentService(userInfo.currency, axiosInstance)).then((cartContent) => {
            if (cartContent?.status === "ok") {
                let cartData = cartContent.formattedData.data;
                const offlineCart = getFromStorage("cart") || undefined;
                syncCarts(cartData, offlineCart, userInfo.currency).then((updatedData) => {
                    removeFromStorage("cart");
                    setOfflineCart(null);
                    setOfflineCartCount(0);

                    if (updatedData.status === "ok") {
                        let successData = updatedData.formattedData.data;
                        setCartCount(countCart(successData));
                        setCart(successData);
                    }
                    //Si hay un error se maneja mas abajo
                    cartContent = updatedData;
                });
            }

            if (cartContent?.status === "ko" && cartContent?.alertData) {
                const { data } = cartContent.alertData;
                let serviceText = data.serviceText;
                try {
                    let parsed = JSON.parse(data?.serviceText)?.message?.[0] ?? null;
                    if (parsed) {
                        serviceText = parsed;
                    }
                } catch (error) {
                    console.error(error);
                }
                handleModal({
                    type: "accept",
                    data: {
                        ...data,
                        serviceText,
                        callbackAction: (action) => {
                            switch (action) {
                                case "ok":
                                    return resetCart().then((res) => {
                                        if (res?.status === "ok") {
                                            return true;
                                        }
                                        return false;
                                    });
                                case "close":
                                case "cancel":
                                default:
                                    return false;
                            }
                        },
                    },
                });
            }

        })

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    const syncCarts = async (onlineCart, offlineCart, currency) => {
        if (!offlineCart || offlineCart?.products?.length === 0)
            return Promise.resolve({
                status: "ok",
                formattedData: { data: onlineCart },
            });

        for await (const item of offlineCart?.products) {
            const found = onlineCart.products.find(
                ({ product }) => product.id === item.product.id
            );
            if (found) {
                found.product.qty = item.product.qty;
                await updateCartItem({
                    data: {
                        cart_id: found.cartItemId,
                        product: found.product.id,
                        qty: found.product.qty,
                    },
                });
            } else {
                // new offline item
                await addCartItem({ productData: item.product });
            }
        }
        return await getCartContentService(currency, axiosInstance);
    };


    return null
}

export default useGetInitialCart
