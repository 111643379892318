import { URL_LOGOUT } from "@services/common/utils/settings";
import { removeFromStorage } from "@utils/storage";
import responseEvaluation from "@services/common/utils/serviceResponseEvaluation";

const Logout = async (axiosInstance) => {
  const apiURL = `${URL_LOGOUT}`;

  return await axiosInstance
    .post(apiURL, null)
    .then((response) => {
      const evaluation = responseEvaluation(response);
      if (evaluation.status === "ok") {
        removeFromStorage("token");
        removeFromStorage("stamp");
        removeFromStorage("agent");
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return { status: "ko" };
    });
};

export default Logout;

