import axios from "axios";
import axiosRetry from "axios-retry";
import {
  AUTH_PASS,
  AUTH_PASSB,
  URL_BASE,
} from "@services/common/utils/settings";

const withRetry = (x) =>
  axiosRetry(x, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export const axiosGeneric = axios.create({
  baseURL: URL_BASE,
  headers: { "Content-Type": "application/json" },
});
withRetry(axiosGeneric);

//Usar desde el globalContext, no desde aqui
export const axiosAuthNoUsar = axios.create({
  baseURL: URL_BASE,
  headers: { "Content-Type": "application/json" },
});

withRetry(axiosAuthNoUsar);

//Login con codigo
export const axiosBasicAuthA = axios.create({
  baseURL: URL_BASE,
  headers: {
    Authorization: `Basic ${AUTH_PASS}`,
    "Content-Type": "application/json",
  },
});
withRetry(axiosBasicAuthA);

//Se usa para login con password
export const axiosBasicAuthB = axios.create({
  baseURL: URL_BASE,
  headers: {
    Authorization: `Basic ${AUTH_PASSB}`,
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
withRetry(axiosBasicAuthB);
