import { useContext } from "react";
import GlobalContext from "@context/global/GlobalContext";
import useCancellablePromise from "@customHooks/common/useCancellablePromise";
import getLogoutService from "@services/common/login/getLogout";
import { useReset } from "../cart/useReset";
import useLocalizedRoutes from "../useLocalizedRoutes";

export function useLogout() {
  const { goLogout, logoutLoading, setLogoutLoading, axiosInstance } = useContext(GlobalContext);
  const { reset } = useReset();
  const { cancellablePromise } = useCancellablePromise();
  const { navigate } = useLocalizedRoutes();
  
  const callLogout = async () => {
    setLogoutLoading(true);
    return cancellablePromise(getLogoutService(axiosInstance))
      .then((response) => {
        if (response.status === "ok") {
          goLogout();
          reset();
          navigate("HOME")
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLogoutLoading(false);
      });
  };
  
  return { loading: logoutLoading, callLogout };
}
