import {
  URL_SHIPPING_BASE,
  URL_GET_API_PROVINCES,
} from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";
import { axiosGeneric } from "@src/utils/axiosInstances";

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.data;
};

const getProvinces = async () => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_GET_API_PROVINCES}`;

  return axiosGeneric
    .get(apiURL)
    .then((response) => {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
export default getProvinces;
