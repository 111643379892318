import { useState, useContext } from "react";
//Context
import GlobalContext from "@context/global/GlobalContext";
//Hooks
import useCancellablePromise from "@customHooks/common/useCancellablePromise";
import useModal from "@customHooks/common/modal/useModal";
//import {useAllInfo} from "@customHooks/common/account/useAllInfo"
import useLocalizedRoutes from "@customHooks/common/useLocalizedRoutes";
//Getters
import getSenderService from "@src/services/envios/sender/getSender";
import addSenderService from "@src/services/envios/sender/addSender";
import editSenderService from "@src/services/envios/sender/editSender";

export function useSender() {
  const { axiosInstance, sender, setSender } = useContext(GlobalContext);
  const { handleModal } = useModal();
  const { cancellablePromise } = useCancellablePromise();
  const [loadingSender, setLoadingSender] = useState(false);
  //const { accountInfo } = useAllInfo()
  const { navigate } = useLocalizedRoutes();

  const getSender = async () => {
    setLoadingSender(true);
    const data = {
      cuballamaId: null, //accountInfo.accountId,
    };
    return cancellablePromise(getSenderService(data, axiosInstance))
      .then((response) => {
        if (response.canceled) return null;
        if (response.status !== "ok") {
          //Mostrar alerta
          if (response.useAlert) handleModal(response.alertData);
          //Hacer redirect
          if (response.useRedirect) navigate(response.route || "HOME");
          return null;
        }
        if (!response.formattedData) {
          setSender({ status: "empty" });
          return response;
        }
        setSender({ ...response.formattedData, status: "ok" });
        return response;
      })
      .catch((error) => {
        console.error(error.response);
        return null;
      })
      .finally(() => {
        setLoadingSender(false);
      });
  };

  const addSender = ({ data, callback }) => {
    setLoadingSender(true);
    cancellablePromise(addSenderService(data, axiosInstance))
      .then((response) => {
        if (response.canceled) return null;
        if (response.status === "ok") {
          if (typeof callback === "function") {
            callback({ status: "ok" });
          }
        } else {
          //Mostrar alerta
          if (response.useAlert) handleModal(response.alertData);
          //Hacer redirect
          if (response.useRedirect) navigate(response.route || "HOME");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingSender(false);
      });
  };

  const editSender = ({ data, callback }) => {
    if (!loadingSender) {
      setLoadingSender(true);
      cancellablePromise(editSenderService(data, axiosInstance))
        .then((response) => {
          if (response.canceled) return null;
          if (response.status === "ok") {
            setSender(response.formattedData.sender);
            if (typeof callback === "function") {
              callback({ status: "ok" });
            }
          } else {
            //Mostrar alerta
            if (response.useAlert) handleModal(response.alertData);
            //Hacer redirect
            if (response.useRedirect) navigate(response.route || "HOME");
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoadingSender(false);
        });
    }
  };

  return {
    loadingSender,
    setLoadingSender,
    getSender,
    addSender,
    editSender,
    sender,
    setSender,
  };
}
