import { useContext } from "react";
//Context
import GlobalContext from "@context/global/GlobalContext";
import { removeFromStorage } from "@src/utils/storage";

export function useReset() {
  const { setCart, setCartCount, setOfflineCart, setOfflineCartCount } =
    useContext(GlobalContext);

  const reset = () => {
    removeFromStorage("cart");
    setCart(null);
    setCartCount(0);
    setOfflineCart(null);
    setOfflineCartCount(0);
  };

  return { reset };
}
