export const regExFromString = (value) => {
  return value.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const colorLog = (
  message,
  color = "white",
  background = "black",
  size = "2em"
) => {
  console.info(
    `%c${message}`,
    `background:${background}; color:${color}; font-size:${size}; `
  );
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
};

export const generateRndmString = ({ characters = 32, prefix = "" } = {}) => {
  let rndm = prefix;
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < characters; i++) {
    rndm += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return rndm;
};

export const formatSearchDateTime = (dateObj, timeObj) => {
  const yy = dateObj.getFullYear().toString();
  const mm = ("0" + (dateObj.getMonth() + 1)).toString().slice(-2);
  const dd = ("0" + dateObj.getDate()).toString().slice(-2);
  const h = ("0" + timeObj.getHours()).toString().slice(-2);
  const m = ("0" + timeObj.getMinutes()).toString().slice(-2);
  return `${yy}-${mm}-${dd} ${h}:${m}:00`;
};

export const formatCallDuration = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const minString = (minutes < 10 ? "0" : "") + minutes;
  const seconds = totalSeconds % 60;
  const secString = (seconds < 10 ? "0" : "") + seconds;
  return minString + ":" + secString;
};

export const firstUppercase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const checkIfNauta = (email) => {
  return (
    email.indexOf("@nauta.co.cu") > -1 || email.indexOf("@nauta.com.cu") > -1
  );
};

export const getCookie = (name) => {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

export const removeAccents = (str) => {
  return str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "";
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const unicodeUnEscape = (string) => {
  return string.replace(/%u([\dA-Z]{4})|%([\dA-Z]{2})/g, function (_, m1, m2) {
    return String.fromCharCode(parseInt("0x" + (m1 || m2)));
  });
};

export const ASINCode = (asin) => {
  // Expresión regular para verificar el formato de un código ASIN de Amazon
  const regex = /^[A-Z0-9]{10}$/;
  return regex.test(asin);
};

export const getEstimatedDeliveryURL = (url) => {
  // Tratamiento para obtener y cambiar la URL que vienen del servicio y que necesita el proyecto para construir la página de datos al pedirla
  const regex = /https:\/\/www\.cuballama\.com\/envios\/entrega-estimada\/(.*)/;
  const match = url.match(regex);

  if (match && match[1]) {
    // La parte de la URL después de "https://www.cuballama.com/envios/"
    const remainingURL = match[1];

    // Reemplazar "/" con "-" y eliminar cualquier otro carácter especial
    const formattedURL = remainingURL
      .replace(/\//g, "-")
      .replace(/[^a-zA-Z0-9-]/g, "");

    // Formatear la URL final con el prefijo "/" y sufijo "/"
    return `/${formattedURL}/`;
  } else {
    // Si no se encuentra ninguna coincidencia
    return null;
  }
};

export const formatDate = ({ date, timezone }) => {
  // Fecha en formato ISO 8601
  const isoDateString = date.replace(" ", "T"); // Convertir a formato ISO 8601
  const dateObj = new Date(isoDateString);

  if (isNaN(dateObj)) {
    throw new Error("Invalid date");
  }

  // Opciones de formato para solo la fecha
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: timezone,
  };

  // Convertir la fecha a la cadena formateada sin tiempo
  return dateObj.toLocaleDateString("es-ES", options);
};

export const removePreTags = (htmlString) => {
  // Elimina las etiquetas <pre> y </pre> y retorna el contenido
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Obtener todas las etiquetas <pre>
  const preTags = tempElement.getElementsByTagName("pre");

  // Iterar sobre cada etiqueta <pre>
  for (let i = 0; i < preTags.length; i++) {
    // Obtener el contenido interno de la etiqueta <pre>
    const contenidoPre = preTags[i].innerHTML;
    // Reemplazar la etiqueta <pre> y su contenido por el contenido interno
    preTags[i].outerHTML = contenidoPre;
  }

  return tempElement.innerHTML;
};

export const removePTags = (htmlString) => {
  // Elimina las etiquetas <p> y </p> y retorna el contenido
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Obtener todas las etiquetas <p> como una lista
  const pTags = Array.from(tempElement.getElementsByTagName("p"));

  // Iterar sobre cada etiqueta <p>
  pTags.forEach((pTag) => {
    // Obtener el contenido interno de la etiqueta <p>
    const contenidoPre = pTag.innerHTML;

    // Reemplazar la etiqueta <p> y su contenido por el contenido interno
    pTag.outerHTML = contenidoPre;
  });

  return tempElement.innerHTML;
};

export const getIdType = (id) => {
  // Expresión regular para UUID (formato 8-4-4-4-12)
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  // Verificar si es un número (orderId)
  if (typeof id === "number" || (!isNaN(id) && Number.isInteger(Number(id)))) {
    return "orderId";
  }

  // Verificar si cumple con el formato UUID
  if (typeof id === "string" && uuidRegex.test(id)) {
    return "uuId";
  }

  // En caso de no cumplir con ninguno
  return null;
};

export const getFilterString = (item) => {
  let string = "";
  switch (item.productType.toUpperCase()) {
    case "SALDO":
      string = `${item.title} ${item.description}`;
      break;
    case "CAD_CREDIT":
      string = `${item.title} ${item.description}`;
      break;
    case "CALL":
      string = `${item.title} ${item.description}`;
      break;
    case "CASHOUT":
      string = `${item.title} ${item.description} ${item.info.productItemOutDTO[0].destinationName} ${item.info.productItemOutDTO[0].destination}`;
      break;
    case "CREDIT_TO_USER":
      string = `${item.title} ${item.description} ${item.info.productItemOutDTO[0].destinationName} ${item.info.productItemOutDTO[0].destination}`;
      break;
    case "DELIVERY":
      string = `${item.info.dataMap.contactName} ${item.info.dataMap.restaurantName}`;
      break;
    case "ENVIOS":
      string = `${item.info.dataMap.contactName}`; //`${item.title} ${item.description}`
      break;
    case "EUR_CREDIT":
      string = `${item.title} ${item.description}`;
      break;
    case "MINUTE":
      string = `${item.title} ${item.description}`;
      break;
    case "PREPAID_CARD":
      string = `${item.title} ${item.description} ${item.info.productItemOutDTO[0].destinationName} ${item.info.productItemOutDTO[0].destination}`;
      break;
    case "PREPAID_CARD_PAYMENT":
      string = `${item.title} ${item.description} ${item.info.productItemOutDTO[0].destinationName} ${item.info.productItemOutDTO[0].destination}`;
      break;
    case "REMESA":
      string = `${item.title} ${item.description} ${item.info.productItemOutDTO[0].destinationName} ${item.info.productItemOutDTO[0].destination}`;
      break;
    case "THIRD_PARTY_ORDER":
      string = `${item.title} ${item.description}`;
      break;
    case "TOPUP":
      string = `${item.title} ${item.description} ${item.info.productItemOutDTO[0].destinationName} ${item.info.productItemOutDTO[0].destination}`;
      break;
    case "TOPUP_NAUTA":
      string = `${item.title} ${item.description} ${item.info.productItemOutDTO[0].destinationName} ${item.info.productItemOutDTO[0].destination}`;
      break;
    case "TRANSFER":
      string = `${item.title} ${item.description} ${item.info.productItemOutDTO[0].destinationName} ${item.info.productItemOutDTO[0].destination}`;
      break;
    case "TRANSFER_REQUEST":
      string = `${item.title} ${item.description}`;
      break;
    default:
      string = ``;
  }
  return string.toLowerCase();
};

export const isPending = (element) => {
  let pending = false;
  switch (element.productType.toUpperCase()) {
    /*case "DELIVERY":
      pending = element.info.status < 3
    break;*/
    case "ENVIOS":
      const pendingCodes = [0, 1, 3, 4, 5, 10, 11, 20, 21];
      pending = pendingCodes.includes(element.info.dataMap.status.id);
      break;
    /*case 'TRANSFER_REQUEST':
      const pendingStrings = ['Pendiente de aprobar', 'Pendiente de aceptar']
      pending = pendingStrings.includes(element.status)
      break*/
    default: //REMESA / PREPAIDCARD / PREPAIDCARDPAYMENT / MINUTE / TOPUP / NAUTA / BALANCE / DELIVERY
      pending = element.status === "En Proceso";
  }
  return pending;
};

export const avatarParams = (theString) => {
  return {
    sx: {
      bgcolor: "avatar.bgcolor",
      color: "primary.main",
      p: 2,
    },
    children: `${theString.split(" ")[0][0]}${theString.split(" ")[1][0]}`,
  };
};

export const getSlugFromUrlAPI = (url) => {
  try {
    const match = url.match(/\/([^/]+)\/?$/);
    return match ? match[1] : "";
  } catch (e) {
    console.error("Invalid URL:", e);
    return "";
  }
};

