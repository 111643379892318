import {
  URL_SHIPPING_BASE,
  URL_GET_API_SENDER,
} from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.data;
};

//eslint-disable-next-line no-unused-vars
const getSender = async ({ cuballamaId = null }, axiosInstance) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_GET_API_SENDER}`;
  // const bodyData = {
  //   //"cuballama_id":cuballamaId,
  // };
  return axiosInstance
    .get(apiURL)
    .then((response) => {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  // //Se procesa la petición
  // const response = await ({axiosInstance: axiosInstance, method: "GET", url: apiURL, useToken: "TOKEN_API_ENVIOS", callData:{body:bodyData, bodyEncode: "JSON"}})
  // //Evaluamos la respuesta del
  // const evaluation = responseEvaluation(response)
  // if(evaluation.status === "ok"){
  //   evaluation.formattedData = formatApiResponse(response.data)
  // }
  // return evaluation
};
export default getSender;
