//BASE
export const CLIENT_ID = "cll2_web";
export const URL_BASE = process.env.GATSBY_API_BASE;
export const URL_SHIPPING_BASE = process.env.GATSBY_ENVIOS_API_BASE;
export const URL_TRAVELS_BASE = process.env.GATSBY_TRAVEL_BASE;
export const AUTH_PASS = process.env.GATSBY_AUTH_PASS;
export const AUTH_PASSB = process.env.GATSBY_AUTH_PASSB;
//Token
export const URL_REFRESH_TOKEN = `uaa/oauth/token`;
//Verifications
export const URL_2FA_VERIFICATION = "uaa/device/verify";
export const URL_EMAIL_VERIFICATION = "uaa/email/change/verify";
//Login
export const URL_LOGOUT = "uaa/secured/logout";
export const URL_LOGIN_STEP1 = `uaa/login/passwordless`;
export const URL_LOGIN_STEP2 = `uaa/oauth/token`;
export const URL_LOGIN_STEP3 = `uaa/secured/data`;
export const URL_LOGIN_STEP4 = `uaa/secured/data/email`;
export const URL_PASSWORD_STEP1 = `uaa/recoverpw/init`;
export const URL_PASSWORD_STEP2 = `uaa/recoverpw/verify`;
//Account info
export const URL_GET_USER = "users/secured/data";
export const URL_GET_USER_ACCOUNT = "uaa/secured/data";
export const URL_GET_CREDIT = "orders/secured/usercredit";
//Account update
export const URL_USER_UPDATE_NAME = `uaa/secured/name/update`;
export const URL_USER_UPDATE_EMAIL = `uaa/secured/data/email`;
export const URL_USER_UPDATE_PASS = `uaa/secured/data/password`;
export const URL_USER_UPDATE_PHONE = `uaa/secured/phone/update`;
export const URL_USER_UPDATE_PHONE_VERIFY = `uaa/secured/phone/verify`;
//Account settings
export const URL_GET_CURRENCIES = "users/currency/get";
export const URL_CHANGE_CURRENCY = "users/secured/currency/add?currency=";
export const URL_GET_RATE = "users/secured/rates/get?";
export const URL_UPDATE_BALANCE = "users/secured/credit/transfer";
export const URL_NOTIFICATIONS_CONFIG = "users/secured/subscribePromotion";

//Contacts
export const URL_GET_CONTACTS = "users/secured/contacts/";
export const URL_ADD_CONTACT = "users/secured/contacts/add";
export const URL_DELETE_CONTACT = "users/secured/contacts/delete";
//Call Rates
export const URL_SERVICE_RATES = "users/call/rates";
//Cart
export const URL_ADD_TO_CART = "cart/secured/CUBALLAMA/add";
export const URL_COUNT_CART_ITEMS = "cart/secured/CUBALLAMA/count";
export const URL_REMOVE_FROM_CART = "cart/secured/CUBALLAMA/remove/";
export const URL_GET_ALL_CART_ITEMS = "cart/secured/CUBALLAMA/data";
//Catalog Services
export const URL_AVAILABLE_SERVICES = "catalog/secured/productTypes";
// Operators
export const URL_COUNTRIES_ALL = "catalog/secured/topup/countries?size=100";
export const URL_OPERATOR_ALL = "catalog/secured/operator";
export const URL_OPERATOR_BY_NAME = "catalog/secured/operator/name/";
export const URL_OPERATORS_BY_COUNTRY = "catalog/operator/country/";
// Offers
export const URL_OFFERS_BASE = "catalog/offers/cll/";
export const URL_OFFER_BY_ID = "catalog/offer?id=";
export const URL_HISTORY = "reports/secured/history";
export const URL_HISTORY_VISIBLE = "reports/secured/visible";
export const URL_HISTORY_TOPUP_MODIFY = "orders/secured/fo/topup/update";
// Remittances
export const URL_CASHOUT_INFO = "catalog/secured/cashout/cashoutinfo";
export const URL_CASHOUT_CURRENCIES = "catalog/secured/cashout/currencies";
export const URL_VALIDATE_CASHOUT = "catalog/secured/cashout/cashoutvalidator?";
// Remittances cards
export const URL_ADD_REMITTANCE_CARD = "customer/secured/prepaidcard/add";
export const URL_DELETE_REMITTANCE_CARD = "customer/secured/prepaidcard/deletebyid?id=";
export const URL_GET_RELATED_CARDS = "customer/secured/prepaidcard/all"
export const URL_GET_CARD_BY_PHONE = "customer/secured/prepaidcard/byaccountandphone?phoneNumber="
export const URL_GET_CARD_BY_CARDID = "customer/secured/prepaidcard/bycardid?cardId="
export const URL_BUY_CARD_AVAILABILITY = "orders/secured/prepaid-card/can-be-assigned-card?contactNumber=";
// Bank Remittances
export const URL_BANK_REMITTANCE_RECIPIENT = "orders/secured/remesa/tobankaccount/recipient"
export const URL_BANK_REMITTANCE_GET_SENDER = "users/secured/user/address"
export const URL_BANK_REMITTANCE_SENDER = "orders/secured/remesa/tobankaccount/sender"
// Address
export const URL_ADDRESS_BY_USER_NUMBER = "users/secured/contacts/address/id?mobile=";
export const URL_ADD_ADDRESS = "users/secured/contacts/address/add";
//Balance transfer
export const URL_BALANCE_TRANSFER = "/orders/secured/transfer/create"
//Balance request
export const URL_REQUEST_BALANCE = "reports/secured/checkout/request/credit"
export const URL_BALANCE_REQUEST = "reports/secured/credit/request"
export const URL_PAY_REQUESTS = "reports/secured/checkout/request"
export const URL_NON_SECURE_PAY_REQUESTS = "reports/checkout/request"
export const URL_CANCEL_PAY_REQUESTS = "reports/secured/checkout/request/cancel/"
export const URL_EXTERNAL_CHECKOUT   = "orders/third-party/checkout"
// Gift Cards
export const URL_GET_GIFT_CARDS = "users/secured/user/giftcards";
export const URL_GET_IMAGES = "users/secured/product/image-url?product=CREDIT_TO_USER&size=100&page=0"
export const URL_EXCHANGE_GIFT_CARD = "users/secured/user/claimgiftcard?giftcardId=";
export const URL_EXCHANGE_GIFT_CARD_COUPON = "users/secured/balance/gift/coupon/";
//Checkout and payment
export const URL_GET_BRAINTREE_TOKEN = "customer/secured/braintree/token";
export const URL_GET_PAYMENTMETHOD_NONCE = "customer/secured/nonce?paymentMethodId=";
//export const URL_PAYMENT_METHODS = "customer/secured/paymentmethod/all";
export const URL_PAYMENT_METHODS = "customer/secured/paymentmethodandusercredit/all";
export const URL_CASH_TICKET_OPTIONS = "customer/secured/dlocal/paymentmethods/";
export const URL_ADD_CASH = "customer/secured/identitydocument/add";
export const URL_GET_BEFORE_PAYING = "customer/secured/before-to-pay";

export const URL_ADD_PAYPAL_CREDIT_CARD = "customer/secured/creditcard/add";
export const URL_ADD_CREDIT_CARD = "customer/secured/card/v2/add";
export const URL_CHECK_REQUIRE_CREDIT_CARD_ZIP = "customer/secured/countries/requirepc";
export const URL_UPDATE_CREDIT_CARD_ZIP = "customer/secured/bt/paymentmethod/update";
export const URL_VERIFY_CREDIT_CARD = "customer/secured/validate/creditcard";
export const URL_DELETE_PAYMENT_METHOD = "customer/secured/paymentmethod/delete";
export const URL_GET_CHECKOUT_TOTAL = "catalog/secured/checkout/total";
//export const URL_CHECKOUT_PAYPAL = "orders/secured/paypal/sdk/checkout";
export const URL_CHECKOUT = "orders/secured/paymentmethod/checkout";
export const URL_CYBERSOURCE_SETUP = "customer/secured/cybersource/setupPayerAuth";
export const URL_CYBERSOURCE_CHECK_ENROLLMENT = "customer/secured/cybersource/checkEnrollment";
export const URL_CYBERSOURCE_RETURN_URL = "customer/3ds/endStep";

//THIRD PARTIES
export const URL_PROIP = `https://pro.ip-api.com/json/?key=kYgryVqIIc1JvvW`;
export const URL_BAMBORA_NONCE = "https://api.na.bambora.com/scripts/tokenization/tokens";
export const URL_PAYPAL_OBJECTS = "https://www.paypalobjects.com/api/checkout.js";
export const URL_GOOGLE_PAY = "https://pay.google.com/gp/p/js/pay.js";
export const URL_BRAINTREE_CLIENT = "https://js.braintreegateway.com/web/3.76.2/js/client.min.js";
export const URL_BRAINTREE_CHECKOUT = "https://js.braintreegateway.com/web/3.76.2/js/paypal-checkout.min.js";
export const URL_BRAINTREE_SECURE = "https://js.braintreegateway.com/web/3.76.2/js/three-d-secure.min.js";
export const URL_BRAINTREE_APPLE = "https://js.braintreegateway.com/web/3.76.2/js/apple-pay.min.js";
export const URL_BRAINTREE_GPAY = "https://js.braintreegateway.com/web/3.76.2/js/google-payment.min.js";
export const URL_UNSUBSCRIBE = "https://qzmf05g2mc.execute-api.us-east-1.amazonaws.com/Prod/cuballama/unsubscribe/";

//ENVIOS
export const URL_GET_API_CATEGORIES = "categories/all";
export const URL_GET_API_CATEGORIES_SEO = "categories/seo";
export const URL_GET_API_CATEGORIES_HOME = "categories/home";
export const URL_GET_API_CATEGORY_DETAIL = "category";
export const URL_GET_API_CATEGORIES_HITS = "categories/hits";
export const URL_GET_API_PRODUCTS_BY_CATEGORY = "category/products";
export const URL_GET_API_PRODUCTS_DETAIL = "product/detail";
export const URL_GET_API_AMAZON_PRODUCTS_DETAIL = "product-detail";
export const URL_GET_API_PRODUCTS_DETAIL_BY_SLUG = "product/slug";
export const URL_GET_API_PRODUCTS_HITS = "product-hits";
export const URL_GET_API_PRODUCT_SEARCH = "product/search";
export const URL_GET_API_AMAZON_PRODUCT_SEARCH = "product-search";
export const URL_GET_API_MUNICIPALITY = "municipality";
export const URL_GET_API_PROVINCES = "provinces";
export const URL_GET_API_LOCATIONS = "provinces/locations";
export const URL_GET_API_BRANDS = "brands";
export const URL_GET_API_STORES = "get-stores";
export const URL_GET_API_BRANDS_SEARCH = "brands/search";
export const URL_GET_API_SENDER = "secured/sender/get";
export const URL_GET_API_SENDER_ADD = "secured/sender/add";
export const URL_GET_API_SENDER_UPDATE = "secured/sender/edit";
export const URL_GET_API_RECEIVERS = "secured/contacts/list";
export const URL_GET_API_RECEIVERS_ADD = "secured/contacts/add";
export const URL_GET_API_RECEIVERS_UPDATE = "secured/contacts/update";
export const URL_GET_API_RECEIVERS_REMOVE = "secured/contacts/remove";
export const URL_GET_API_RECEIVERS_VALIDATE = "secured/receivers/validate";
export const URL_GET_API_ORDERS = "secured/get-list-order";
export const URL_GET_API_FULL_DATA_TO_CLIENT = "secured/get-full-data-to-client";
export const URL_GET_API_UPDATE_EXTRA_DATA = "secured/update-extra-data";
export const URL_GET_API_ESTIMATED_DELIVERY = "estimated-delivery";
//ENVIOS CART
export const URL_API_ADD_TO_CART = "secured/cart/add";
export const URL_API_REMOVE_FROM_CART = "secured/cart/delete";
export const URL_API_REMOVE_ALL_FROM_CART = "secured/cart/reset";
export const URL_API_UPDATE_FROM_CART = "secured/cart/update";
export const URL_API_GET_ALL_CART_ITEMS = "secured/cart/get";
//ENVIOS CHECKOUT
export const URL_SHIPPING_CHECKOUT = `secured/checkout`;
export const URL_SHIPPING_CHECKOUT_PAYMENT = "secured/checkout/payment";
export const URL_SHIPPING_GET_PAYMENT_METHODS = "secured/payment/methods";
export const URL_SHIPPING_CHECKOUT_NON_PAYMENT = "secured/complete-payment";

//PROMOS
//export const URL_VISA_MEXICO = `https://n8n.prod.cubatelefono.com/webhook/formulario-visa`;
export const URL_VISA_MEXICO = `https://webhook.site/cll-webhook`;
