import { URL_GET_USER_ACCOUNT } from "@services/common/utils/settings";
import responseEvaluation from "@services/common/utils/serviceResponseEvaluation";

const formatApiResponse = (apiResponse) => {
  const data = {
    accountId: apiResponse.data.data.accountId,
    email: apiResponse.data.data.email,
    firstName: apiResponse.data.data.firstName,
    lastName: apiResponse.data.data.lastName,
    phoneNumber: apiResponse.data.data.phoneNumber,
    prefix: apiResponse.data.data.prefix,
    registerDate: apiResponse.data.data.registerDate,
    alias: apiResponse.data.data.alias,
  };
  return data;
};

const getAccountInfo = async (axiosInstance) => {
  const apiURL = `${URL_GET_USER_ACCOUNT}`;
  return axiosInstance
    .get(apiURL)
    .then((response) => {
      const evaluation = responseEvaluation(response);
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  // //Se procesa la petición
  // const response = await ({axiosInstance: axiosInstance, method: "GET", url: apiURL, useToken: "TOKEN_YES"})
  // //Evaluamos la respuesta del
  // const evaluation = responseEvaluation(response)
  // if(evaluation.status === "ok"){
  //   evaluation.formattedData = formatApiResponse(response.data)
  // }
  // return evaluation
};
export default getAccountInfo;
