import { useContext } from "react";
//Context
import GlobalContext from "@context/global/GlobalContext";
import { useLogout } from "@customHooks/common/login/useLogout";
export function useAuthorized() {
  const {
    authorized,
    setAuthorized,
    setAgent,
    axiosInstance,
    firstLoad,
    setFirstLoad,
    timeLeft,
  } = useContext(GlobalContext);
  const { callLogout } = useLogout();

  return {
    authorized,
    setAuthorized,
    setAgent,
    axiosInstance,
    callLogout,
    firstLoad,
    setFirstLoad,
    timeLeft,
  };
}

