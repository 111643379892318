import {
  URL_SHIPPING_BASE,
  URL_API_REMOVE_ALL_FROM_CART,
} from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";

const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data;
};

const resetCart = async (axiosInstance) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_API_REMOVE_ALL_FROM_CART}`;

  return axiosInstance
    .post(apiURL, { cart_id: null, channel: "web" })
    .then(function (response) {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response.data);
      }
      return evaluation;
    })
    .catch(function (error) {
      console.error(error);
      return null;
    });

  // const response = await ({axiosInstance, method: "POST", url: apiURL, useToken: "TOKEN_YES", callData:{body: data, bodyEncode: "JSON"}})
  // //Evaluamos la respuesta del
  // const evaluation = responseEvaluation(response)
  // if(evaluation.status === "ok"){
  //   evaluation.formattedData = response.data.data
  // }
  // return evaluation
};
export default resetCart;
