import { useEffect, useState, useContext } from "react";
//Context
import GlobalContext from "@context/global/GlobalContext";
//Hooks
import useCancellablePromise from "@customHooks/common/useCancellablePromise";
//Getters
import getProIp from "@services/common/thirdParties/getProIp";

export function useProIp() {
  const { ipData, setIpData } = useContext(GlobalContext);
  const { cancellablePromise } = useCancellablePromise();

  const [loading, setLoading] = useState(false);

  useEffect(
    function () {
      //Sólo se procesa si aún no e ha procesado la info
      if (ipData === null && !loading) {
        setLoading(true);
        cancellablePromise(getProIp())
          .then((response) => {
            setIpData(response);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIpData(false);
            setLoading(false);
          });
      }
    },
    [ipData, setIpData, loading, cancellablePromise]
  );
  return { loading, ipData };
}

