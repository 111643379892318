// TRACKING EVENTS
export const checkoutTracking = (orderId, data, firebase = null) => {
  let conversionData = {
    transaction_id: orderId,
    affiliation: "Cuballama store",
    value: data.amount,
    currency: data.currency,
    tax: 0,
    shipping: 0,
    items: data.items.map((item) => {
      return {
        id: item.id,
        name: `${item.name} ${item.finalPrice} - ${item.salePrice}`,
        list_name: item.name,
        brand: item.brand,
        category: item.category,
        quantity: 1,
        price: item.amount,
      };
    }),
  };
  //Analytics
  typeof window !== "undefined" &&
    typeof window.gtag === "function" &&
    window.gtag("event", "purchase", conversionData);
  conversionData.send_to = ""; //'AW-409257642/pupwCNbQjIoCEKqNk8MB'
  //Ads
  typeof window !== "undefined" &&
    typeof window.gtag === "function" &&
    window.gtag("event", "purchase", conversionData);

  //Segment
  typeof window !== "undefined" &&
    window.analytics &&
    window.analytics.track("Order Completed", {
      currency: data.currency,
      orderId: orderId,
      products: data.items.map((item) => {
        return {
          id: item.id,
          name: item.name,
          category: item.category,
          currency: item.currency,
          quantity: 1,
          price: item.amount,
        };
      }),
      total: data.amount,
    });
  //Adjust
  typeof window !== "undefined" &&
    window.Adjust &&
    window.Adjust.trackEvent({
      eventToken: adjustTokens["PURCHASE_ENVIOS"],
      revenue: data.amount,
      currency: data.currency,
    });
};
//GTAG
export const gtagRegisterEvent = () => {
  try {
    typeof window !== "undefined" &&
      typeof window.gtag === "function" &&
      window.gtag("event", "conversion", { send_to: "" }); //'AW-409257642/5fdBCP_H8YkCEKqNk8MB'});
    //firebase.analytics().logEvent(firebase.analytics.EventName.SIGN_UP);
  } catch (e) {
    console.error(e);
  }
};
//SEGMENT && ADJUST
export const trackingEvent = ({
  eventString,
  data = null,
  sendToSegment = true,
  sendToAdjust = true,
}) => {
  const segment =
    typeof window !== "undefined" && window.analytics ? true : false;
  const adjust = typeof window !== "undefined" && window.Adjust ? true : false;
  try {
    if (segment && sendToSegment) {
      switch (eventString) {
        case "PRODUCT_ADDED":
          window.analytics.track("Product Added", {
            cart_id: data.cart_id,
            category: data.product.type,
            currency: data.product.offer.currency,
            name: data.product.offer.title,
            price: data.product.offer.finalPrice,
            product_id: data.product.offer.id,
            quantity: 1,
          });
          break;
        case "PRODUCT_REMOVED":
          window.analytics.track("Product Removed", data);
          break;
        case "TRACK_PAGE":
          window.analytics.page();
          break;
        case "IDENTITY":
          window.analytics.identify(data.accountId, {
            userId: data.accountId,
          });
          break;
        case "SIGNED_UP":
          window.analytics.track("Signed Up", data);
          break;
        case "SIGNED_IN":
          window.analytics.track("Signed In", data);
          break;
        case "INIT_CHECKOUT":
          window.analytics.track("Checkout Started", data);
          break;
        case "PAYMENT_DECLINED":
          window.analytics.track("Payment Declined", data);
          break;
        default:
      }
    }
    if (adjust && sendToAdjust && adjustTokens[eventString]) {
      window.Adjust.trackEvent({
        eventToken: adjustTokens[eventString],
      });
    }
  } catch (e) {
    console.error(e);
  }
};
//Adjust tokens
const adjustTokens = {
  PURCHASE_ENVIOS: "sz2ufv",
};
