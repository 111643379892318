import React from "react";
import { GlobalProvider } from "@context/global/GlobalContext";
import { ModalProvider } from "@context/modal/ModalContext";
import LoadingPage from "@advanced/cll2/layout/loadingPage";
import ErrorFallback from "@advanced/cll2/errorHandling/errorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { checkStorage, getFromStorage, addToStorage } from "@utils/storage";
import { generateRndmString } from "@utils/helper";
import "@styles/global.css";
import "@styles/material.css"; //react-phone-input-2
import DataInitializer from "@src/modules/DataInitializer";

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GlobalProvider>
        <ModalProvider>
          <DataInitializer />
          <LoadingPage element={element} />
        </ModalProvider>
      </GlobalProvider>
    </ErrorBoundary>
  );
};

export const onInitialClientRender = () => {
  if (checkStorage()) {
    const loadScript = ({ src = null, callback = null } = {}) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.type = "application/javascript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    };

    const initGoogleTagManager = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };
      if (typeof window.gtag === "function") {
        window.gtag("js", new Date());
        window.gtag("config", process.env.GATSBY_GOOGLE_TAG_TRACKING_ID, {
          page_path: window.location.pathname,
        });
        window.gtag("config", process.env.GATSBY_GOOGLE_TAG_TRACKING_ID_2, {
          page_path: window.location.pathname,
        });
      }
    };

    loadScript({
      src: `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_TAG_TRACKING_ID}`,
      callback: initGoogleTagManager,
    });

    const deviceId = getFromStorage("deviceId");
    if (!deviceId) {
      const initFingerprintJS = () => {
        window.FingerprintJS.load({
          token: "wkoC8U5xXuRxMezDGztI",
          endpoint: "https://fp.cuballama.com",
        })
          .then((fp) => fp.get())
          .then((result) => {
            const visitorId =
              result && result.visitorId
                ? result.visitorId
                : generateRndmString(32, "F_");
            window.visitorId = visitorId;
            addToStorage("deviceId", visitorId);
          })
          .catch((error) => {
            console.error(error);
            const visitorId = generateRndmString(32, "F_");
            window.visitorId = visitorId;
            addToStorage("deviceId", visitorId);
          });
      };
      loadScript({
        src: "https://cdn.jsdelivr.net/npm/@fingerprintjs/fingerprintjs-pro@3/dist/fp.min.js",
        callback: initFingerprintJS,
      });
    } else {
      window.visitorId = deviceId;
    }
  }
};

