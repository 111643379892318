import { URL_AVAILABLE_SERVICES } from "@services/common/utils/settings";
import responseEvaluation from "@services/common/utils/serviceResponseEvaluation";

const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.elements;
};

const getAvailableServices = async (axiosInstance) => {
  const apiURL = `${URL_AVAILABLE_SERVICES}`;

  return axiosInstance
    .get(apiURL)
    .then((response) => {
      const evaluation = responseEvaluation(response);
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });

  // //Se procesa la petición
  // const response = await ({axiosInstance: axiosInstance, method: "GET", url: apiURL, useToken: "TOKEN_YES"})
  // //Evaluamos la respuesta del
  // const evaluation = responseEvaluation(response)
  // if(evaluation.status === "ok"){
  //   evaluation.formattedData = formatApiResponse(response.data)
  // }
  // return evaluation
};
export default getAvailableServices;
