import { useEffect, useContext, useState } from "react";
import { useReceivers } from "@customHooks/envios/receivers/useReceivers";
import { useSender } from "@customHooks/envios/sender/useSender";
import GlobalContext from "@context/global/GlobalContext";
import { checkStorage, getFromStorage, removeFromStorage, addToStorage } from "@src/utils/storage";
import { useProvinces } from "@src/customHooks/envios/provinces/useProvinces";
import useGetInitialCart from "@src/customHooks/common/cart/useGetInitialCart";

//Este componente trae todos los datos necesarios del usuario
const DataInitializer = () => {
    const { setReceiver, authorized, setSearchProvinceId, permissionGranted, setDataLoaded } =
        useContext(GlobalContext);
    const { getAllReceivers } = useReceivers();
    const { getAllProvinces } = useProvinces();
    const { getSender } = useSender();

    useEffect(() => {
        const provinceId = getFromStorage("m-province") ?? process.env.GATSBY_DEFAULT_PROVINCE;
        getAllProvinces();
        setSearchProvinceId(provinceId);
        
        return () => { };
        //eslint-disable-next-line
    }, []);

    //Servicios que requieren token
    useEffect(() => {
        if (!authorized) {
            setDataLoaded(true);
            return;
        }
        getAllReceivers()
            .then((x) => {
                const localReceiver = getFromStorage("receiver");
                const { status, formattedData: responseReceivers } = x;
                if (status !== "ok" || !responseReceivers) return;
                const foundReceiver = responseReceivers.find(
                    (receiver) => receiver.receiver_id === parseInt(localReceiver)
                );
                // Si el receiver que tenemos en local no coincide con ninguno de la lista lo removemos
                if (!foundReceiver) {
                    removeFromStorage("receiver");
                }
                // Obtenemos el receptor favorito si lo tiene asignado
                const foundDefaultReceiver = responseReceivers.find(
                    (receiver) => receiver.isDefault === 1
                );
                // Obtenemos el último receptor utilizado en una compra si ha realizado al menos una compra
                const foundLastUsedReceiver = responseReceivers.find(
                    (receiver) => receiver.isLastUsed === true
                );
                addToStorage("receiver", foundReceiver ?? foundLastUsedReceiver ?? foundDefaultReceiver);
                setReceiver(foundReceiver ?? foundLastUsedReceiver ?? foundDefaultReceiver);
            })
            .catch((error) => {
                console.error("Error fetching receivers:", error);
            }).finally(() => {
                setDataLoaded(true);
            });
        getSender();

        return () => {
        }
        //eslint-disable-next-line
    }, [authorized]);


    //Traer todos los datos del carrito
    useGetInitialCart()

    // Adjust
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [loadGranted, setLoadGranted] = useState(false);

    const onScriptsStatusChange = () => {
        //Sólo cuando se resuleva si hay permiso o no
        if (permissionGranted !== null) {
            //Sólo se ejecuta una vez
            if (!alreadyLoaded) {
                setAlreadyLoaded(true)
                setLoadGranted(permissionGranted)
                //Script de gtag con variable de permiso
                if (typeof window !== undefined && typeof window.gtag === "function") {
                    window.gtag("consent", 'update', { 'analytics_storage': permissionGranted ? 'granted' : 'denied' });
                }
            }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onScriptsStatusChange, [permissionGranted]);

    if (checkStorage()) {
        const loadScript = ({ src = null, callback = null } = {}) => {
            const script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.type = "application/javascript";
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) callback();
            };
        };

        const initAdjust = () => {
            (function(t, a, e, r, s, l, d, n, o) {
                t.Adjust = t.Adjust || {};
                t.Adjust_q = t.Adjust_q || [];
                for (var c = 0; c < l.length; c++) d(t.Adjust, t.Adjust_q, l[c]);
                n = a.createElement("script");
                o = a.getElementsByTagName("script")[0];
                n.async = true;
                n.src = "https://cdn.adjust.com/adjust-latest.min.js";
                n.onload = function() {
                    for (var a = 0; a < t.Adjust_q.length; a++)
                        t.Adjust[t.Adjust_q[a][0]].apply(t.Adjust, t.Adjust_q[a][1]);
                    t.Adjust_q = [];
                };
                o.parentNode.insertBefore(n, o);
            })(window, document, 0, 0, 0, [
                "initSdk", "trackEvent", "addGlobalCallbackParameters",
                "addGlobalPartnerParameters", "removeGlobalCallbackParameter",
                "removeGlobalPartnerParameter", "clearGlobalCallbackParameters",
                "clearGlobalPartnerParameters", "switchToOfflineMode",
                "switchBackToOnlineMode", "stop", "restart", "gdprForgetMe",
                "disableThirdPartySharing"
            ], function(t, a, e) {
                t[e] = function() { a.push([e, arguments]); };
            });

            if (typeof window.Adjust !== "undefined") {
                window.Adjust.initSdk({
                    appToken: process.env.GATSBY_ADJUST_APP_TOKEN,
                    environment: process.env.GATSBY_ADJUST_ENVIRONMENT,
                    logLevel: process.env.GATSBY_ADJUST_ENVIRONMENT === 'sandbox' ? 'verbose' : 'error'
                });
            }
        };

        if (loadGranted) {
            loadScript({
                src: "https://cdn.adjust.com/adjust-latest.min.js",
                callback: initAdjust,
            });
        }
    }
    // FIN Adjust

    return null;
};

export default DataInitializer;
