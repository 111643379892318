import {
  URL_SHIPPING_BASE,
  URL_API_UPDATE_FROM_CART,
} from "@services/common/utils/settings";
import responseEvaluation from "@services/common/utils/serviceResponseEvaluation";

const updateCartItem = async ({ cartId, product, qty, axiosInstance }) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_API_UPDATE_FROM_CART}`;
  const data = {
    cart_id: cartId,
    product: product,
    qty: qty,

    channel: "web",
  };

  return axiosInstance
    .post(apiURL, data)
    .then(function (response) {
      const evaluation = responseEvaluation(response);
      if (evaluation.status === "ok") {
        evaluation.formattedData = response.data.data;
      }
      return evaluation;
    })
    .catch(function (error) {
      console.error(error);
      return null;
    });

  // const response = await ({
  //   axiosInstance,
  //   method: "POST",
  //   url: apiURL,
  //   useToken: "TOKEN_YES",
  //   callData: { body: data, bodyEncode: "JSON" },
  // });
  // //Evaluamos la respuesta del
  // const evaluation = responseEvaluation(response);
  // if (evaluation.status === "ok") {
  //   evaluation.formattedData = response.data.data;
  // }
  // return evaluation;
};
export default updateCartItem;

