import { useState, useContext } from "react";
//Context
import GlobalContext from "@context/global/GlobalContext";
//Hooks
import useCancellablePromise from "@customHooks/common/useCancellablePromise";
import useModal from "@customHooks/common/modal/useModal";
import useLocalizedRoutes from "@customHooks/common/useLocalizedRoutes"; //useIntl,
//Getters
import getUserInfoService from "@services/common/account/getUserInfo";
import getAccountInfoService from "@services/common/account/getAccountInfo";
import getCreditService from "@services/common/account/getCredit";
import getAvailableServices from "@services/common/account/getAvailableServices";
//UTILS
//import { segmentIdentify } from "@utils/tracking";
import { trackingEvent } from "@utils/tracking";
import * as Sentry from "@sentry/browser";

export function useAllInfo() {
  const {
    userInfo,
    setUserInfo,
    setCurrency,
    accountInfo,
    setAccountInfo,
    credit,
    setCredit,
    cartCount,
    cart,
    hasData,
    setHasData,
    axiosInstance,
    setAvailableServices,
  } = useContext(GlobalContext);
  const { navigate } = useLocalizedRoutes();
  const { cancellablePromise } = useCancellablePromise();
  const [loading, setLoading] = useState(false);
  const { handleModal } = useModal();

  const getAllInfo = async () => {
    setLoading(true);
    return Promise.all([
      cancellablePromise(getUserInfoService(axiosInstance)),
      cancellablePromise(getAccountInfoService(axiosInstance)),
      cancellablePromise(getAvailableServices(axiosInstance)),
    ]).then(([userInfo, accountInfo, availableServices]) => {
      let data = {};
      if (userInfo?.status === "ok") {
        const userData = userInfo.formattedData;
        setUserInfo(userData);
        setCurrency(userData.currency);
        data.accountId = userData.accountId;
        data.currency = userData.currency;
        data.uuid = userData.uuid;
      }
      if (accountInfo?.status === "ok") {
        const accountData = accountInfo.formattedData;
        setAccountInfo(accountData);
        setHasData(true);
        data.firstName = accountData.firstName;
        data.phoneNumber = accountData.phoneNumber;
      }

      if (availableServices?.status === "ok") {
        setAvailableServices(availableServices.formattedData);
      }

      //Sentry.setUser(data)
      Sentry.setUser({
        id: data?.accountId ?? "N/A",
        username: data?.firstName ?? "N/A",
      });
      //segmentIdentify(data);
      trackingEvent({eventString: "IDENTITY", data})
      return data;
    });
  };

  const getUserInfo = (callback = null) => {
    if (!loading) {
      setLoading(true);
      cancellablePromise(getUserInfoService(axiosInstance))
        .then((response) => {
          if (!response.canceled) {
            if (response.status === "ok") {
              setUserInfo(response.formattedData);
              setCurrency(response.formattedData.currency);
              if (typeof callback === "function") {
                callback({ status: "ok", currency: response.formattedData });
              }
            } else {
              //Mostrar alerta
              if (response.useAlert) handleModal(response.alertData);
              //Hacer redirect
              if (response.useRedirect) navigate(response.route || "HOME");
              //Mostrar error en form
              //if(response.formId) setFormResponse({formId: response.formId, multiText: response.multiText, message: response.message })
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getAccountInfo = (callback = null) => {
    if (!loading) {
      setLoading(true);
      cancellablePromise(getAccountInfoService(axiosInstance))
        .then((response) => {
          if (!response.canceled) {
            if (response.status === "ok") {
              setAccountInfo(response.formattedData);
              setHasData(true);
              if (typeof callback === "function") {
                callback({ status: "ok", data: response.formattedData });
              }
            } else {
              //Mostrar alerta
              if (response.useAlert) handleModal(response.alertData);
              //Hacer redirect
              if (response.useRedirect) navigate(response.route || "HOME");
              //Mostrar error en form
              //if(response.formId) setFormResponse({formId: response.formId, multiText: response.multiText, message: response.message })
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  //EL balance y su valor en el servicio depende del currency / idioma
  const updateUserCredit = ({ currency = null, callback = null } = {}) => {
    cancellablePromise(
      getCreditService({
        currency: currency || userInfo?.currency,
        axiosInstance,
      })
    )
      .then((response) => {
        if (!response.canceled) {
          if (response.status === "ok") {
            setCredit(response.formattedData.amount);
            if (typeof callback === "function") {
              callback({
                status: "ok",
                currency: currency,
                credit: response.formattedData.amount,
              });
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return {
    loading,
    userInfo,
    setUserInfo,
    accountInfo,
    setAccountInfo,
    cartCount,
    cart,
    credit,
    hasData,
    updateUserCredit,
    getUserInfo,
    getAccountInfo,
    getAllInfo,
  };
}
