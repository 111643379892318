import {
  URL_SHIPPING_BASE,
  URL_API_ADD_TO_CART,
} from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";

const formatApiResponse = (apiResponse) => {
  const data = {
    total: apiResponse.data.data.checkoutData.products.lenght,
    data: apiResponse.data.data.checkoutData,
  };
  return data;
};

const addCartItem = async ({ productData, axiosInstance }) => {
  if (!productData) return null;
  const apiURL = `${URL_SHIPPING_BASE}${URL_API_ADD_TO_CART}`;
  return axiosInstance
    .post(apiURL, productData)
    .then((response) => {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });

  // const response = await ({axiosInstance, method: "POST", url: apiURL, useToken: "TOKEN_YES", callData:{body: productData, bodyEncode: "JSON"}})
  // //Evaluamos la respuesta del
  // const evaluation = responseEvaluation(response)
  // if(evaluation.status === "ok"){
  //   evaluation.formattedData = formatApiResponse(response.data)
  // }
  // return evaluation
};
export default addCartItem;
