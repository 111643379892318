export const checkStorage = () => {
  try {
    const test = "test";
    window.localStorage.setItem(`cllWebApp-${test}`, test);
    localStorage.removeItem(`cllWebApp-${test}`);
    return true;
  } catch (e) {
    return false;
  }
};
export const getFromStorage = (name) => {
  try {
    const data = localStorage.getItem(`cllWebApp-${name}`);
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};

export const getAccessToken = () => {
  try {
    const base = getFromStorage("token");
    return base ? base.access_token : null;
  } catch (e) {
    return null;
  }
};

export const getRefreshToken = () => {
  try {
    const base = getFromStorage("token");
    return base ? base.refresh_token : null;
  } catch (e) {
    return null;
  }
};

export const addToStorage = (name, value) => {
  try {
    window.localStorage.setItem(`cllWebApp-${name}`, JSON.stringify(value));
  } catch (e) {
    return null;
  }
};

export const removeFromStorage = (name) => {
  try {
    localStorage.removeItem(`cllWebApp-${name}`);
  } catch (e) {
    return null;
  }
};

