import {
  URL_SHIPPING_BASE,
  URL_GET_API_RECEIVERS_UPDATE,
} from "@services/common/utils/settings";
import responseEvaluation from "@services/common/utils/serviceResponseEvaluation";

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.data;
};

const updateReceivers = async (data, axiosInstance) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_GET_API_RECEIVERS_UPDATE}`;
  const bodyData = data;
  return axiosInstance
    .post(apiURL, bodyData)
    .then((response) => {
      const evaluation = responseEvaluation(response);
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
export default updateReceivers;
