import {
  URL_SHIPPING_BASE,
  URL_GET_API_RECEIVERS_ADD,
} from "@services/common/utils/settings";
import responseEvaluation from "@services/common/utils/serviceResponseEvaluation";

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.data;
};

const addReceiver = async (data, axiosInstance) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_GET_API_RECEIVERS_ADD}`;
  const bodyData = data;

  return axiosInstance
    .post(apiURL, bodyData)
    .then((response) => {
      const evaluation = responseEvaluation(response);
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      } else {
        evaluation.useAlert = true;
        evaluation.alertData = {
          type: "accept",
          data: {
            icon: "warning",
            title: "No se ha podido validar",
            text: "Puede que uno de los campos no sea correcto. Revisa el número de carné de identidad, el teléfono de contacto o el código postal.",
          },
        };
        evaluation.formattedData = response.data.data;
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
export default addReceiver;

