exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceso-codigo-index-jsx": () => import("./../../../src/pages/acceso/codigo/index.jsx" /* webpackChunkName: "component---src-pages-acceso-codigo-index-jsx" */),
  "component---src-pages-acceso-contrasena-cambiar-index-jsx": () => import("./../../../src/pages/acceso/contrasena/cambiar/index.jsx" /* webpackChunkName: "component---src-pages-acceso-contrasena-cambiar-index-jsx" */),
  "component---src-pages-acceso-contrasena-index-jsx": () => import("./../../../src/pages/acceso/contrasena/index.jsx" /* webpackChunkName: "component---src-pages-acceso-contrasena-index-jsx" */),
  "component---src-pages-acceso-contrasena-recuperar-index-jsx": () => import("./../../../src/pages/acceso/contrasena/recuperar/index.jsx" /* webpackChunkName: "component---src-pages-acceso-contrasena-recuperar-index-jsx" */),
  "component---src-pages-acceso-correo-index-jsx": () => import("./../../../src/pages/acceso/correo/index.jsx" /* webpackChunkName: "component---src-pages-acceso-correo-index-jsx" */),
  "component---src-pages-acceso-correo-verificacion-index-jsx": () => import("./../../../src/pages/acceso/correo/verificacion/index.jsx" /* webpackChunkName: "component---src-pages-acceso-correo-verificacion-index-jsx" */),
  "component---src-pages-acceso-imperso-index-jsx": () => import("./../../../src/pages/acceso/imperso/index.jsx" /* webpackChunkName: "component---src-pages-acceso-imperso-index-jsx" */),
  "component---src-pages-acceso-index-jsx": () => import("./../../../src/pages/acceso/index.jsx" /* webpackChunkName: "component---src-pages-acceso-index-jsx" */),
  "component---src-pages-busquedas-index-jsx": () => import("./../../../src/pages/busquedas/index.jsx" /* webpackChunkName: "component---src-pages-busquedas-index-jsx" */),
  "component---src-pages-categorias-index-jsx": () => import("./../../../src/pages/categorias/index.jsx" /* webpackChunkName: "component---src-pages-categorias-index-jsx" */),
  "component---src-pages-completar-compra-jsx": () => import("./../../../src/pages/completar-compra/[...].jsx" /* webpackChunkName: "component---src-pages-completar-compra-jsx" */),
  "component---src-pages-complete-order-payment-jsx": () => import("./../../../src/pages/complete-order-payment/[...].jsx" /* webpackChunkName: "component---src-pages-complete-order-payment-jsx" */),
  "component---src-pages-entrega-estimada-[slug]-js": () => import("./../../../src/pages/entrega-estimada/[slug].js" /* webpackChunkName: "component---src-pages-entrega-estimada-[slug]-js" */),
  "component---src-pages-es-ca-entrega-estimada-jsx": () => import("./../../../src/pages/es-CA/entrega-estimada/[...].jsx" /* webpackChunkName: "component---src-pages-es-ca-entrega-estimada-jsx" */),
  "component---src-pages-es-ca-producto-jsx": () => import("./../../../src/pages/es-CA/producto/[...].jsx" /* webpackChunkName: "component---src-pages-es-ca-producto-jsx" */),
  "component---src-pages-es-es-entrega-estimada-jsx": () => import("./../../../src/pages/es-ES/entrega-estimada/[...].jsx" /* webpackChunkName: "component---src-pages-es-es-entrega-estimada-jsx" */),
  "component---src-pages-es-es-producto-jsx": () => import("./../../../src/pages/es-ES/producto/[...].jsx" /* webpackChunkName: "component---src-pages-es-es-producto-jsx" */),
  "component---src-pages-es-us-entrega-estimada-[slug]-jsx": () => import("./../../../src/pages/es-US/entrega-estimada/[slug].jsx" /* webpackChunkName: "component---src-pages-es-us-entrega-estimada-[slug]-jsx" */),
  "component---src-pages-es-us-producto-[slug]-jsx": () => import("./../../../src/pages/es-US/producto/[slug].jsx" /* webpackChunkName: "component---src-pages-es-us-producto-[slug]-jsx" */),
  "component---src-pages-los-mas-vendidos-index-jsx": () => import("./../../../src/pages/los-mas-vendidos/index.jsx" /* webpackChunkName: "component---src-pages-los-mas-vendidos-index-jsx" */),
  "component---src-pages-mi-cuenta-jsx": () => import("./../../../src/pages/mi-cuenta/[...].jsx" /* webpackChunkName: "component---src-pages-mi-cuenta-jsx" */),
  "component---src-pages-producto-[slug]-js": () => import("./../../../src/pages/producto/[slug].js" /* webpackChunkName: "component---src-pages-producto-[slug]-js" */),
  "component---src-pages-route-account-js": () => import("./../../../src/pages/ROUTE_ACCOUNT.js" /* webpackChunkName: "component---src-pages-route-account-js" */),
  "component---src-pages-route-product-js": () => import("./../../../src/pages/ROUTE_PRODUCT.js" /* webpackChunkName: "component---src-pages-route-product-js" */),
  "component---src-pages-todos-los-productos-index-jsx": () => import("./../../../src/pages/todos-los-productos/index.jsx" /* webpackChunkName: "component---src-pages-todos-los-productos-index-jsx" */),
  "component---src-pages-usuario-ordenes-pendientes-jsx": () => import("./../../../src/pages/usuario/ordenes/pendientes/[...].jsx" /* webpackChunkName: "component---src-pages-usuario-ordenes-pendientes-jsx" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-checkout-request-page-js": () => import("./../../../src/templates/checkoutRequestPage.js" /* webpackChunkName: "component---src-templates-checkout-request-page-js" */),
  "component---src-templates-credit-request-page-js": () => import("./../../../src/templates/creditRequestPage.js" /* webpackChunkName: "component---src-templates-credit-request-page-js" */),
  "component---src-templates-redirect-page-js": () => import("./../../../src/templates/redirectPage.js" /* webpackChunkName: "component---src-templates-redirect-page-js" */),
  "component---src-templates-subcategories-page-js": () => import("./../../../src/templates/subcategoriesPage.js" /* webpackChunkName: "component---src-templates-subcategories-page-js" */),
  "component---src-templates-subcategory-page-js": () => import("./../../../src/templates/subcategoryPage.js" /* webpackChunkName: "component---src-templates-subcategory-page-js" */),
  "component---src-templates-token-page-js": () => import("./../../../src/templates/tokenPage.js" /* webpackChunkName: "component---src-templates-token-page-js" */),
  "component---src-templates-unsubscribe-page-js": () => import("./../../../src/templates/unsubscribePage.js" /* webpackChunkName: "component---src-templates-unsubscribe-page-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/wpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */)
}

