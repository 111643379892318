import {
  URL_SHIPPING_BASE,
  URL_API_GET_ALL_CART_ITEMS,
} from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";

const formatApiResponse = (apiResponse) => {
  const data = {
    total: apiResponse.data.data.checkoutData.products.length,
    data: apiResponse.data.data.checkoutData,
  };
  return data;
};

const getCartContent = async (currency, axiosInstance) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_API_GET_ALL_CART_ITEMS}?currencyCode=${currency || process.env.GATSBY_DEFAULT_CURRENCY}`;
  return axiosInstance(apiURL)
    .then(function (response) {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch(function (error) {
      console.error(error);
      return null;
    });
};
export default getCartContent;
