import { URL_GET_CREDIT } from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse, baseCurrency) => {
  const creditString =
    baseCurrency === process.env.GATSBY_DEFAULT_CURRENCY
      ? "CALL_CREDIT"
      : `${baseCurrency}_CREDIT`;
  const callCredit = apiResponse.data.data.elements.find((element) => {
    return element.creditType === creditString ? true : false;
  });
  return { amount: callCredit.amount };
};

const getCredit = async ({ currency, axiosInstance }) => {
  const apiURL = `${URL_GET_CREDIT}`;
  return axiosInstance
    .get(apiURL)
    .then((response) => {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response, currency);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  // //Se procesa la petición
  // const response = await ({axiosInstance: axiosInstance, method: "GET", url: apiURL, useToken: "TOKEN_YES"})
  // //Evaluamos la respuesta del
  // const evaluation = responseEvaluation(response)
  // if(evaluation.status === "ok"){
  //   evaluation.formattedData = formatApiResponse(response, currency)
  // }
  // return evaluation
};
export default getCredit;
