import { useState, useContext } from "react";
import GlobalContext from "@context/global/GlobalContext";
import useCancellablePromise from "@customHooks/common/useCancellablePromise";
import useModal from "@customHooks/common/modal/useModal";
import useLocalizedRoutes from "@customHooks/common/useLocalizedRoutes";
import addCartItemService from "@services/envios/cart/addCartItem";
import updateCartItemService from "@services/envios/cart/updateCartItem";
import getCartContentService from "@services/envios/cart/getCartContent";
import removeCartItemService from "@services/envios/cart/removeCartItem";
import resetCartService from "@services/envios/cart/resetCart";

export function useOnlineCart() {
  const {
    cart,
    setCart,
    cartCount,
    setCartCount,
    setTempRoute,
    tempRoute,
    axiosInstance,
    userInfo,
  } = useContext(GlobalContext);

  const { t, navigate } = useLocalizedRoutes();
  const { handleModal } = useModal();
  const { cancellablePromise } = useCancellablePromise();
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const addCartItem = async ({ productData }) => {
    setLoading(true);
    return addCartItemService({ productData, axiosInstance })
      .then((response) => {
        if (response.canceled) return null;
        if (response.status === "ok") {
          setCart(response.formattedData.data);
          setCartCount(countCart(response.formattedData.data)); //response.formattedData.total
          return { status: "ok", data: response.formattedData };
        }
        //Mostrar alerta
        if (response.useAlert) {
          const { type, data } = response.alertData;
          handleModal({
            type: type === "accept" ? "confirm" : type,
            data: {
              ...data,
              serviceText:
                type === "accept"
                  ? [data.serviceText, t("cart.error.addAnyways")]
                  : data.serviceText,
              callbackAction: (action) => {
                switch (action) {
                  case "ok":
                    cleanCartAndAddItem(productData);
                    return true;
                  case "close":
                  case "cancel":
                  default:
                    return false;
                }
              },
            },
          });
          return null;
        }
        //Hacer redirect
        if (response.useRedirect) navigate(response.route || "HOME");
        return null;
      })
      .catch((error) => {
        console.error(error);
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cleanCartAndAddItem = async (productData) => {
    return resetCart().then((res) => {
      if (res?.status === "ok") {
        return addCartItem({ productData });
      }
    });
  };

  const countCart = (cart) =>
    cart.products
      .map((item) => item.product.qty || 1)
      .reduce((acc, item) => acc + item, 0);

  const getCartContent = async ({ redirect = false } = {}) => {
    if (!userInfo) return;
    setLoading(true);
    return getCartContentService(userInfo.currency, axiosInstance)
      .then((response) => {
        if (response.canceled) return null;
        if (response.status === "ok") {
          setCart(response.formattedData.data);
          setCartCount(countCart(response.formattedData.data)); //response.formattedData.total
          if (redirect) {
            navigate(tempRoute || "ROUTE_CATEGORIES");
            setTempRoute(null);
            return null;
          }
          return { status: "ok", data: response.formattedData };
        }
        if (response.useAlert) handleModal(response.alertData);
        if (response.useRedirect) navigate(response.route || "HOME");
      })
      .catch((error) => {
        console.error(error);
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCartItem = async ({ data }) => {
    setLoadingUpdate(true);
    return updateCartItemService({
      cartId: data.cart_id,
      product: data.product,
      qty: data.qty,
      axiosInstance,
    })
      .then((response) => {
        if (response.canceled) return null;
        if (response.status === "ok") {
          return response;
        }
        //Mostrar alerta
        if (response.useAlert) handleModal(response.alertData);
        //Hacer redirect
        if (response.useRedirect) navigate(response.route || "HOME");
        return null;
      })
      .catch((error) => {
        console.error(error);
        return error;
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  const removeCartItem = async ({ data }) => {
    setLoadingDelete(true);
    return cancellablePromise(
      removeCartItemService({ cartId: data.cart_id, axiosInstance })
    )
      .then((response) => {
        if (response.canceled) return null;
        if (response.status === "ok") {
          return response;
        }
        if (response.useAlert) handleModal(response.alertData);
        if (response.useRedirect) navigate(response.route || "HOME");
        return null;
      })
      .catch((error) => {
        console.error(error);
        return error;
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  const resetCart = async () => {
    setLoading(true);
    return resetCartService(axiosInstance)
      .then(async (response) => {
        if (response.canceled) return null;
        if (response.status === "ok") {
          return getCartContent().then((data) => {
            if (data.status === "ok") {
              return { status: "ok", data: response.formattedData };
            }
          });
        }
        if (response.useAlert) handleModal(response.alertData);
        if (response.useRedirect) navigate(response.route || "HOME");
        return null;
      })
      .catch((error) => {
        console.error(error);
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    loadingUpdate,
    loadingDelete,
    cart,
    cartCount,
    addCartItem,
    getCartContent,
    updateCartItem,
    removeCartItem,
    resetCart,
  };
}
