import { URL_REFRESH_TOKEN, CLIENT_ID } from "@services/common/utils/settings";
import { axiosBasicAuthA } from "./axiosInstances";

export const refreshToken = async ({ refresh_token = null } = {}) => {
  if (!refresh_token) return { status: "ko" };
  return axiosBasicAuthA({
    method: "post",
    url: URL_REFRESH_TOKEN,
    params: {
      refresh_token: refresh_token,
      grant_type: "refresh_token",
      client_id: CLIENT_ID,
    },
  })
    .then((tokenRefreshResponse) => {
      return { status: "ok", data: tokenRefreshResponse.data };
    })
    .catch((error) => {
      console.error(error.response);
      return { status: "ko" };
    });
};
